<template>
  <div class="new-monitor-container">
    <div class="monitor-card">
      <div class="title title--green">体质</div>
      <hr class="divider divider--green" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">身高：</span>
          <Input class="input-field" v-model="monitor.sg" placeholder="请输入身高"></Input>
          <span class="input-suffix">cm</span>
        </Col>
        <Col span="8">
          <span class="input-label">腰围：</span>
          <Input class="input-field" v-model="monitor.yw" placeholder="请输入腰围"></Input>
          <span class="input-suffix">cm</span>
        </Col>
        <Col span="8">
          <span class="input-label">臀围：</span>
          <Input class="input-field" v-model="monitor.tw" placeholder="请输入臀围"></Input>
          <span class="input-suffix">cm</span>
        </Col>
      </Row>
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">腰臀比：</span>
          <Input class="input-field" v-model="monitor.ytb" placeholder="请输入腰臀比"></Input>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--orange">人体成分</div>
      <hr class="divider divider--orange" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">体重：</span>
          <Input class="input-field" v-model="monitor.tz" placeholder="请输入体重"></Input>
          <span class="input-suffix">cm</span>
        </Col>
        <Col span="8">
          <span class="input-label">体脂肪率：</span>
          <Input class="input-field" v-model="monitor.tzfl" placeholder="请输入体脂肪率"></Input>
          <span class="input-suffix">cm</span>
        </Col>
        <Col span="8">
          <span class="input-label">内脂指数：</span>
          <Input class="input-field" v-model="monitor.nzzs" placeholder="请输入内脂指数"></Input>
        </Col>
      </Row>
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">体水分率：</span>
          <Input class="input-field" v-model="monitor.tsfl" placeholder="请输入体水分率"></Input>
          <span class="input-suffix">%</span>
        </Col>
        <Col span="8">
          <span class="input-label">骨骼肌率：</span>
          <Input class="input-field" v-model="monitor.ggjl" placeholder="请输入骨骼肌率"></Input>
          <span class="input-suffix">%</span>
        </Col>
        <Col span="8">
          <span class="input-label">骨矿含量：</span>
          <Input class="input-field" v-model="monitor.gkhl" placeholder="请输入骨矿含量"></Input>
          <span class="input-suffix">kg</span>
        </Col>
      </Row>
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">基础代谢：</span>
          <Input class="input-field" v-model="monitor.jcdx" placeholder="请输入基础代谢"></Input>
          <span class="input-suffix">kcal</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--deep-green">握力</div>
      <hr class="divider divider--deep-green" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">握力左：</span>
          <Input class="input-field" v-model="monitor.wlz" placeholder="请输入握力左"></Input>
          <span class="input-suffix">kg</span>
        </Col>
        <Col span="8">
          <span class="input-label">握力右：</span>
          <Input class="input-field" v-model="monitor.wly" placeholder="请输入握力右"></Input>
          <span class="input-suffix">%</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--green">肺活量</div>
      <hr class="divider divider--green" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">肺活量：</span>
          <Input class="input-field" v-model="monitor.fhl" placeholder="请输入肺活量"></Input>
          <span class="input-suffix">ml</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--orange">平衡能力</div>
      <hr class="divider divider--orange" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">闭眼左平衡：</span>
          <Input class="input-field" v-model="monitor.byzph" placeholder="请输入闭眼左平衡"></Input>
          <span class="input-suffix">S</span>
        </Col>
        <Col span="8">
          <span class="input-label">闭眼右平衡：</span>
          <Input class="input-field" v-model="monitor.byyph" placeholder="请输入闭眼右平衡"></Input>
          <span class="input-suffix">S</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--deep-green">血压</div>
      <hr class="divider divider--deep-green" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">收缩压：</span>
          <Input class="input-field" v-model="monitor.ssy" placeholder="请输入收缩压"></Input>
          <span class="input-suffix">mmHg</span>
        </Col>
        <Col span="8">
          <span class="input-label">舒张压：</span>
          <Input class="input-field" v-model="monitor.szy" placeholder="请输入舒张压"></Input>
          <span class="input-suffix">mmHg</span>
        </Col>
        <Col span="8">
          <span class="input-label">心率：</span>
          <Input class="input-field" style="width:55%" v-model="monitor.xl" placeholder="请输入心率"></Input>
          <span class="input-suffix" style="width:10%">次/分</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--green">血氧</div>
      <hr class="divider divider--green" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">血氧：</span>
          <Input class="input-field" v-model="monitor.xy" placeholder="请输入血氧"></Input>
          <span class="input-suffix">%</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--orange">血糖</div>
      <hr class="divider divider--orange" />
      <Row class="input-field-row" :gutter="20">
        <Col span="8">
          <span class="input-label">空腹：</span>
          <Input class="input-field" v-model="monitor.kf" placeholder="请输入空腹血糖值"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
        <Col span="8">
          <span class="input-label">饭后半小时：</span>
          <Input class="input-field" v-model="monitor.fhbxs" placeholder="请输入饭后半小时血糖值"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
        <Col span="8">
          <span class="input-label">饭后一小时：</span>
          <Input class="input-field" v-model="monitor.fhyxs" placeholder="请输入饭后一小时血糖值"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
      </Row>
      <Row class="input-field-row" :gutter="20">
        <Col span="8">
          <span class="input-label">随机：</span>
          <Input class="input-field" v-model="monitor.sjxt" placeholder="请输入随机血糖值"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
        <Col span="8">
          <span class="input-label">饭后两小时：</span>
          <Input class="input-field" v-model="monitor.fhlxs" placeholder="请输入饭后两小时血糖值"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
        <Col span="8">
          <span class="input-label">饭后三小时：</span>
          <Input class="input-field" v-model="monitor.fhsxs" placeholder="请输入饭后三小时血糖值"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--green">血脂</div>
      <hr class="divider divider--green" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">总胆固醇：</span>
          <Input class="input-field" v-model="monitor.cholesterin" placeholder="请输入总胆固醇"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
        <Col span="8">
          <span class="input-label">甘油三脂：</span>
          <Input class="input-field" v-model="monitor.trig" placeholder="请输入甘油三脂"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
        <Col span="8">
          <span class="input-label">高密度脂蛋白：</span>
          <Input class="input-field" v-model="monitor.hdl" placeholder="请输入高密度脂蛋白"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
      </Row>
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">低密度脂蛋白：</span>
          <Input class="input-field" v-model="monitor.ldl" placeholder="请输入低密度脂蛋白"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
      </Row>
    </div>
    <div class="monitor-card">
      <div class="title title--deep-green">尿酸</div>
      <hr class="divider divider--deep-green" />
      <Row class="input-field-row">
        <Col span="8">
          <span class="input-label">尿素：</span>
          <Input class="input-field" v-model="monitor.ns" placeholder="请输入尿素"></Input>
          <span class="input-suffix">mmol/L</span>
        </Col>
      </Row>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="handleBack">返回</Button>
      <Button type="primary" size="large" @click="handleRefresh">刷新</Button>
      <Button type="success" size="large" @click="handleSave">保存</Button>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import memberService from '@/services/memberService';

export default {
	data() {
		return {
			monitor: {
				sg: null,
				yw: '',
				tw: '',
				tz: '',
				tzfl: '',
				nzzs: '',
				tsfl: '',
				ggjl: '',
				gkhl: '',
				jcdx: '',
				wlz: '',
				wly: '',
				fhl: '',
				byzph: '',
				byyph: '',
				ssy: '',
				szy: '',
				xl: '',
				xy: '',
				kf: '',
				fhbxs: '',
				fhyxs: '',
				sjxt: '',
				fhlxs: '',
				fhsxs: '',
				ytb: '',
				cholesterin: '',
				trig: '',
				hdl: '',
				ldl: '',
				ns: '',
			},
		};
	},
	created() {
		memberService
			.newMonitorData({
				method: 0,
				member_id: this.$route.params.member_id,
			})
			.then((data) => {
				this.monitor = data.value;
			});
	},
	methods: {
		handleBack() {
			this.$router.back();
		},
		// method: 0: 查询 1: 录入
		handleSave() {
			const params = {
				method: 1,
				member_id: this.$route.params.member_id,
				value: JSON.stringify(this.monitor),
			};
			memberService.newMonitorData(params).then(() => {
				this.$Message.success('保存成功');
				this.handleBack();
			});
		},
		handleRefresh() {
			const params = {
				method: 2,
				member_id: this.$route.params.member_id,
			};
			memberService.newMonitorData(params).then((data) => {
				this.monitor = data.value;
				this.$Message.success('刷新成功');
			});
		},
	},
};
</script>
<style lang="less" scoped>
.new-monitor-container {
  background-color: #fff;
  padding: 20px;
  .monitor-card {
    margin-bottom: 20px;
    .title {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      text-align: center;
      letter-spacing: 2px;
      &--green {
        background-color: #5ddc53;
      }
      &--orange {
        background-color: #ff9800;
      }
      &--deep-green {
        background-color: #19be6b;
      }
    }
    .divider {
      width: 100%;
      height: 2px;
      &--green {
        background-color: #5ddc53;
      }
      &--orange {
        background-color: #ff9800;
      }
      &--deep-green {
        background-color: #19be6b;
      }
    }
    .input-field-row {
      margin-top: 20px;
    }
    .input-label {
      display: inline-block;
      width: 25%;
      text-align: right;
    }
    .input-field {
      width: 65%;
    }
    .input-suffix {
      display: inline-block;
      width: 5%;
    }
  }
}
</style>
